h1 {
  @apply pt-6 pb-4 text-vacansign-anthracite text-5xl leading-none;
}
h2 {
  @apply pt-6 pb-2 text-vacansign-anthracite text-3xl;
}
h3 {
  @apply pt-6 pb-2 text-vacansign-anthracite text-2xl;
}

p {
  @apply pb-4 text-vacansign-anthracite font-Raleway text-xl;
  font-size: 1rem;
}

#article ul {
  @apply list-disc ml-6 text-xl text-vacansign-anthracite pb-4;
  font-size: 1rem;
}
#article a {
  @apply text-sky-700 font-normal;
}
