.card-container {
  @apply flex flex-col bg-white w-full max-w-sm p-6 space-y-2 rounded-xl shadow-sm border md:h-96;
}

.card-title {
  @apply text-xl md:text-2xl text-left text-vacansign-anthracite py-0;
}

.card-subtext {
  @apply text-left text-neutral-400 text-sm pt-2 py-0;
}

.card-description {
  @apply text-left text-neutral-700 pb-0 pt-2 md:h-40;
  font-size: 1rem;

}

.card-link {
  @apply flex-shrink-0 pt-4;
}
