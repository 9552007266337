@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-Raleway;
}

h1,
h2,
h3,
h4 {
  @apply font-CastleBegale;
}

h1 {
  @apply text-4xl md:text-4xl lg:text-5xl font-extrabold;
}
h2 {
  @apply text-2xl md:text-3xl lg:text-4xl font-bold;
}

.btn {
  @apply shadow-md py-3 px-6 rounded-md transition duration-300;
}

.btn-yellow {
  @apply bg-vacansign-yellow text-white;
}

.btn-white {
  @apply bg-vacansign-white;
}

#icon {
  position: absolute;
  top: 5vh;
  left: 9lvw;
  font-size: 60vh;
  color: #373737;
  opacity: 0.02;
}

#icon-2 {
  position: absolute;
  top: 20vh;
  left: 60vw;
  font-size: 80vh;
  color: #ffc430;
  opacity: 0.03;
}

.inactive-link {
  @apply text-white;
}
